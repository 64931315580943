import { useState, useEffect, useCallback } from "react";
import { get } from "lodash";
import { STORAGE_KEYS } from "helpers/localStorageKeys";
import { useUploads } from "../../contexts/FileUploadsContext";
import useUploadsQuery from "../../api/queries/useUploadsQuery";
import useHideUploadsMutation from "../../api/mutations/useHideUploadsMutation";
import { Upload, UploadStatus } from "../../types";
import Widget from "./Widget";

export function UploadProgress() {
  const inProgressUploads = useUploads();
  const { data } = useUploadsQuery({ subscribe: true });
  const [hide] = useHideUploadsMutation();
  const hideToastSetting: string =
    localStorage.getItem(STORAGE_KEYS.HIDE_UPLOAD_WIDGET) ?? "false";
  const [toastHidden, setToastHidden] = useState(JSON.parse(hideToastSetting));

  const hasQueuedUploads = Object.values(inProgressUploads).some(
    (upload) => upload.status && upload.status === UploadStatus.QUEUED
  );

  const onHideUpload = useCallback(
    (uploadIds: string[]) => hide({ variables: { uploadIds } }),
    [hide]
  );

  const onHideToast = useCallback(() => {
    localStorage.setItem(STORAGE_KEYS.HIDE_UPLOAD_WIDGET, "true");
    setToastHidden(true);
  }, []);

  useEffect(() => {
    if (hasQueuedUploads) {
      localStorage.setItem(STORAGE_KEYS.HIDE_UPLOAD_WIDGET, "false");
      setToastHidden(false);
    }
  }, [hasQueuedUploads]);

  const uploads = get(data, "documentUploads", [])
    .filter((upload: Upload) => !upload.hidden)
    .map((upload: Upload) => {
      const inProgressUpload = inProgressUploads[upload.id] || {};
      return { ...upload, ...inProgressUpload };
    });

  if (uploads.length === 0 || toastHidden) {
    return null;
  }

  return (
    <Widget
      uploads={uploads}
      onHideUpload={onHideUpload}
      onHideToast={onHideToast}
    />
  );
}
