import { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BlankIcon,
  CloudUploadIcon,
  ComparisonIcon,
  CrossIcon,
  TickCircleIcon,
  WarningSignIcon,
} from "evergreen-ui";
import {
  Banner,
  Button,
  Card,
  IconButton,
  Link,
  Pane,
  Progress,
  Shortener,
  Spinner,
  Text,
} from "components/materials";
import { majorScale, minorScale } from "helpers/utilities";
import formatPercent from "helpers/formatPercent";
import { subtract, sumBy } from "helpers/math";
import { rabbet } from "images";
import t from "helpers/translate";
import { UploadStatus } from "../../types";

function useHasUploadsInStatus(uploads) {
  return useCallback(
    (status) => uploads.some((upload) => upload.status === status),
    [uploads]
  );
}

export default function Widget({ onHideUpload, uploads, onHideToast }) {
  const [open, setOpen] = useState(false);
  const hasUploadsInStatus = useHasUploadsInStatus(uploads);
  const handleHideMany = useCallback(
    (status = null) => () => {
      let uploadIds = [];

      if (status) {
        uploadIds = uploads
          .filter((upload) => upload.status === status)
          .map(({ id }) => id);
      } else {
        uploadIds = uploads.map(({ id }) => id);
      }
      return onHideUpload(uploadIds);
    },
    [onHideUpload, uploads]
  );

  const handleClickToggle = () => setOpen((open) => !open);

  const pendingUploads = uploads.filter((upload) =>
    [
      UploadStatus.PENDING_CONFIRMATION,
      UploadStatus.PROCESSING,
      UploadStatus.UPLOADING,
    ].includes(upload.status)
  );

  const message = useMemo(() => {
    if (uploads.length === 0) return "uploadProgress.noUploads";
    if (hasUploadsInStatus(UploadStatus.UPLOADING))
      return "uploadProgress.uploading";
    if (hasUploadsInStatus(UploadStatus.ERROR)) return "uploadProgress.error";
    if (hasUploadsInStatus(UploadStatus.PENDING_CONFIRMATION))
      return "uploadProgress.pendingSplit";
    if (hasUploadsInStatus(UploadStatus.PROCESSING))
      return "uploadProgress.inProgress";
    return "uploadProgress.complete";
  }, [uploads.length, hasUploadsInStatus]);

  return (
    <Pane position="fixed" bottom={majorScale(3)} right={100} zIndex={5}>
      <Card width={500} paddingY={majorScale(2)}>
        {uploads.length > 0 && (
          <Pane
            borderBottom={open && "1px solid #e4eaf0"}
            display="flex"
            flexDirection="column"
            marginBottom={open && majorScale(2)}
            transition="max-height 0.3s"
          >
            <Pane
              paddingX={majorScale(2)}
              maxHeight={open ? 300 : 0}
              overflowY={open ? "auto" : "hidden"}
              paddingBottom={open && minorScale(1)}
            >
              <Actions
                onHideComplete={
                  hasUploadsInStatus(UploadStatus.COMPLETE) &&
                  handleHideMany(UploadStatus.COMPLETE)
                }
                onHideAll={handleHideMany()}
              />
              <UploadList onHide={onHideUpload} uploads={uploads} />
            </Pane>
            {open && <DataPointsBanner uploads={uploads} />}
          </Pane>
        )}
        <Pane
          paddingX={majorScale(2)}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontWeight={600}>{t(message)}</Text>

          <Pane paddingX={majorScale(2)}>
            <AggregateIcon uploads={uploads} />
          </Pane>

          <Text>
            {t("uploadProgress.uploadsComplete", {
              pendingCount: subtract(uploads.length, pendingUploads.length),
              count: uploads.length,
            })}
          </Text>

          {uploads.length > 0 && (
            <Pane width={100}>
              <Button
                appearance="minimal"
                marginLeft={majorScale(1)}
                iconBefore={open ? ArrowDownIcon : ArrowUpIcon}
                cursor="pointer"
                purpose={`file-toast ${open ? "collapse" : "expand"}`}
                onClick={handleClickToggle}
              >
                {open ? "Collapse" : "Expand"}
              </Button>
            </Pane>
          )}
          <IconButton
            appearance="minimal"
            icon={CrossIcon}
            onClick={onHideToast}
            type="button"
          />
        </Pane>
      </Card>
    </Pane>
  );
}

function DataPointsBanner({ uploads }) {
  const datapointCount = sumBy(uploads, "parsedDatapointCount");

  if (datapointCount === 0) return null;

  return (
    <Banner borderTop paddingX={majorScale(2)}>
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Pane marginRight={majorScale(2)}>
          <img alt="Rabbet" src={rabbet} style={{ width: 16 }} />
        </Pane>

        <Text>
          {t("uploadProgress.datapointBanner", {
            count: datapointCount,
            fileCount: t("uploadProgress.fileCount", {
              count: uploads.length,
            }),
          })}
        </Text>
      </Pane>
    </Banner>
  );
}

function AggregateIcon({ uploads }) {
  const hasUploadsInStatus = useHasUploadsInStatus(uploads);

  if (uploads.length === 0) return <BlankIcon size={24} />;

  if (hasUploadsInStatus(UploadStatus.UPLOADING))
    return <Spinner theme={{ spinnerColor: "#1d7ce2" }} size={24} />;

  if (hasUploadsInStatus(UploadStatus.ERROR))
    return <WarningSignIcon color="warning" size={24} />;

  if (hasUploadsInStatus(UploadStatus.PENDING_CONFIRMATION))
    return <ComparisonIcon size={16} />;

  if (hasUploadsInStatus(UploadStatus.PROCESSING))
    return <Spinner theme={{ spinnerColor: "#1d7ce2" }} size={24} />;

  return <TickCircleIcon color="success" size={16} />;
}

function UploadList({ uploads, onHide }) {
  return uploads.map((upload) => (
    <Pane
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      key={upload.id}
      marginY={majorScale(1)}
    >
      <Upload onHide={onHide} upload={upload} />
    </Pane>
  ));
}

function Upload({ onHide, upload }) {
  const history = useHistory();
  const hasLink = [UploadStatus.UPLOADING, UploadStatus.ERROR].includes(
    upload.status
  );
  const UploadLink = hasLink ? Text : Link;

  const uploadLinkURL = upload.drawId
    ? `/projects/${upload.projectId}/draws/${upload.drawId}/documentation`
    : `/projects/${upload.projectId}/documentation`;

  return (
    <Pane
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Pane
        alignItems="center"
        display="flex"
        flex="1"
        justifyContent="space-between"
      >
        <Pane display="flex" alignItems="center">
          <Pane paddingRight={majorScale(2)}>
            <UploadIcon upload={upload} />
          </Pane>
          <UploadLink to={uploadLinkURL} fontWeight={600}>
            <Shortener
              isLink
              limit={
                upload.status === UploadStatus.PENDING_CONFIRMATION ? 40 : 50
              }
              text={upload.fileName}
            />
          </UploadLink>
        </Pane>
        {upload.status === UploadStatus.PENDING_CONFIRMATION && (
          <Button
            marginLeft={majorScale(2)}
            purpose="file-toast split open"
            onClick={() => history.push(`/uploads/${upload.id}`)}
          >
            Split
          </Button>
        )}
        {upload.status === UploadStatus.UPLOADING && (
          <Pane
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            paddingX={majorScale(2)}
            flex="1"
          >
            <Progress
              height={majorScale(1)}
              color="#1d7ce2"
              value={upload.progress ?? 0}
              total={1}
            />

            <Text width={34} paddingLeft={majorScale(2)}>
              {formatPercent(upload.progress ?? 0)}
            </Text>
          </Pane>
        )}
        <IconButton
          appearance="minimal"
          icon={CrossIcon}
          marginLeft={majorScale(1)}
          onClick={() => onHide([upload.id])}
          purpose="file-toast hide-one"
          type="button"
        />
      </Pane>
    </Pane>
  );
}

function UploadIcon({ upload }) {
  switch (upload.status) {
    case UploadStatus.UPLOADING:
      return <CloudUploadIcon size={16} />;
    case UploadStatus.ERROR:
      return <WarningSignIcon color="warning" size={16} />;
    case UploadStatus.PROCESSING:
      return <Spinner theme={{ spinnerColor: "#1d7ce2" }} size={16} />;
    case UploadStatus.PENDING_CONFIRMATION:
      return <ComparisonIcon size={16} />;
    default:
      return <TickCircleIcon color="success" size={16} />;
  }
}

function Actions({ onHideComplete, onHideAll }) {
  return (
    <Pane paddingBottom={majorScale(1)}>
      <Button
        marginRight={majorScale(1)}
        purpose="file-toast hide-all"
        onClick={onHideAll}
      >
        Clear All
      </Button>
      <Button
        purpose="file-toast hide-completed"
        disabled={!onHideComplete}
        onClick={onHideComplete}
      >
        Clear Complete
      </Button>
    </Pane>
  );
}
