import { useContext, Fragment, useState } from "react";
import {
  IconButton,
  Pane,
  Heading,
  ConfigureButtons,
  Paragraph,
} from "components/materials";
import { flattenDeep, orderBy } from "lodash";
import t from "helpers/translate";
import { TASK_STATUS } from "helpers/enums";
import { formatDate, isBefore } from "helpers/dateHelpers";
import { ArrowRightIcon, HistoryIcon, WarningSignIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";
import { getSerializedURLForTasksReport } from "../helpers";
import { TasksHeader } from "./TasksHeader";
import { TaskRow } from "./TaskRow";

export const PROJECT_TASK_SUMMARY_CONFIGURATION_SETTINGS = {
  i: "projectTaskSummary",
  x: 0,
  y: 2,
  w: 2,
  h: 1,
  disabled: false,
};

export function ProjectTaskSummary({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  projects,
  organization,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const [sortKey, setSortKey] = useState("status");
  const [sortDirection, setSortDirection] = useState("desc");

  function navigateToTasksReport(startDate, endDate, showCompleted) {
    return history.push(
      getSerializedURLForTasksReport(
        startDate,
        endDate,
        showCompleted,
        organization.id
      )
    );
  }
  const todayDate = formatDate(new Date());
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const upcomingTasks = orderBy(
    flattenDeep(
      projects.map((project) =>
        project.tasks.map((task) => ({ ...task, projectId: project.id }))
      )
    ).filter(
      ({ projectedCompletionDate, status }) =>
        status !== TASK_STATUS.COMPLETE &&
        projectedCompletionDate &&
        !isBefore(projectedCompletionDate, todayDate)
    ),
    ["projectedCompletionDate"]
  );

  const tasksToDisplay = orderBy(upcomingTasks, [sortKey], [sortDirection]);
  const latestTaskCompletionDate = formatDate(
    upcomingTasks[upcomingTasks.length - 1]?.projectedCompletionDate
  );

  const overdueTasksCount = projects.reduce((acc, { tasks }) => {
    const overdueTasks = tasks.filter((task) => task.isOverdue);
    return acc + overdueTasks.length;
  }, 0);

  const sort = (key, direction) => {
    setSortDirection(direction);
    setSortKey(key);
  };

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Project Task Summary
          </Heading>
          <HistoryIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {overdueTasksCount > 0 && (
          <Pane
            marginLeft="auto"
            marginRight={majorScale(2)}
            display="flex"
            alignItems="center"
          >
            <Pane
              width="170px"
              height="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`1px solid ${theme.colors.errorRed}`}
              background={theme.colors.errorLightRed}
              paddingY={majorScale(1)}
              paddingX={majorScale(1)}
              borderRadius="4px"
              cursor="pointer"
              onClick={() =>
                navigateToTasksReport(
                  todayDate,
                  latestTaskCompletionDate,
                  false
                )
              }
            >
              <WarningSignIcon
                color={theme.colors.errorRed}
                marginRight={majorScale(1)}
              />
              <Paragraph color={theme.colors.errorRed}>
                {overdueTasksCount} overdue{" "}
                {overdueTasksCount > 1 ? "tasks" : "task"}
              </Paragraph>
            </Pane>
          </Pane>
        )}
        <Pane display="flex" alignItems="center">
          {isConfigurable && (
            <ConfigureButtons
              isDisabled={isDisabled}
              cards={cards}
              setCards={setCards}
              name={name}
            />
          )}
          {!isConfigurable && (
            <IconButton
              icon={ArrowRightIcon}
              onClick={() => {
                navigateToTasksReport(
                  todayDate,
                  latestTaskCompletionDate,
                  true
                );
              }}
              appearance="minimal"
              border={`1px solid ${theme.colors.gray400}`}
            />
          )}
        </Pane>
      </Pane>
      <Pane>
        <Pane>
          <Pane>
            {upcomingTasks.length === 0 ? (
              <Paragraph
                alignItems="center"
                textAlign="center"
                padding={majorScale(3)}
              >
                {t(`portfolioInsightsPage.blankSlate.info.tasks`)}
              </Paragraph>
            ) : (
              <Fragment>
                <Pane
                  maxHeight={250}
                  overflowY="scroll"
                  marginTop={majorScale(2)}
                >
                  <Pane>
                    <Pane marginBottom={majorScale(1)} display="flex">
                      <TasksHeader
                        onSelect={sort}
                        currentSortDirection={sortDirection}
                        currentSortKey={sortKey}
                      />
                    </Pane>
                    <Pane>
                      {tasksToDisplay.map((task) => (
                        <TaskRow key={task.id} task={task} />
                      ))}
                    </Pane>
                  </Pane>
                </Pane>
              </Fragment>
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}
