import { defaultTheme } from "evergreen-ui";

const fontFamily = "AvenirNext, arial, sans-serif";

defaultTheme.colors.lightBlue = "#f1f8ff";
defaultTheme.colors.baseBlue = "#1070ca";
defaultTheme.colors.warning = "#ffb020";
defaultTheme.colors.lightYellow = "#fffcf2";
defaultTheme.colors.baseYellow = "#f7d154";
defaultTheme.colors.lightPurple = "#ecefff";
defaultTheme.colors.info = "#3366FF";
defaultTheme.colors.textGray = "#606B85";
defaultTheme.colors.borderGray = "#D8DAE5";
defaultTheme.colors.buttonBlue = "#006DFA";
defaultTheme.colors.linkBlue = "#2952CC";
defaultTheme.colors.text = "#121C2D";
defaultTheme.colors.link = "#078DDE";
defaultTheme.colors.errorRed = "#A73636";
defaultTheme.colors.errorLightRed = "#FBF4F4";

defaultTheme.defaultColors = {};
defaultTheme.defaultColors.headerButtonTextColor = "#e2e9f0";
defaultTheme.defaultColors.headerColor = "#001f40";
defaultTheme.defaultColors.headerIconColor = "#e2e9f0";
defaultTheme.defaultColors.headerIconButtonColor = "#e2e9f0";
defaultTheme.defaultColors.dividerColor = "#e2e9f0";
defaultTheme.defaultColors.headerLinkActive = "#053971";
defaultTheme.defaultColors.headerLinkActiveBoxShadow =
  "0px 0px 0px 3px rgba(16, 112, 202, 0.3)";
defaultTheme.defaultColors.headerLinkActiveHover = "#053971";
defaultTheme.defaultColors.headerLinkInactiveHover = "#042c57";
defaultTheme.defaultColors.headerTextOnHoverColor = "#106BA3";
defaultTheme.defaultColors.primaryColor = "#078DDE";
defaultTheme.defaultColors.primaryButtonTextColor = "#ffffff";
defaultTheme.defaultColors.label = {};
defaultTheme.defaultColors.label.light = "#bcc3ca";
defaultTheme.defaultColors.navigationSelectedText = "#282e34";
defaultTheme.defaultColors.navigationInactiveText = "#7f8f9f";
defaultTheme.defaultColors.navigationInactiveBorder = "#e2e9f0";

defaultTheme.fontFamilies.display = fontFamily;
defaultTheme.fontFamilies.mono = fontFamily;
defaultTheme.fontFamilies.ui = fontFamily;

defaultTheme.tableColors = {};
defaultTheme.tableColors.row = "#ffffff";
defaultTheme.tableColors.rowHovered = "#fcfeff";

defaultTheme.tableColors.alternateRow = "#fcfcfd";
defaultTheme.tableColors.alternateRowHovered = "#f9fbfd";

defaultTheme.tableColors.tableHeader = "#f6f7f8";

defaultTheme.tableColors.sectionHeader = "#ebedf0";
defaultTheme.tableColors.sectionHeaderHovered = "#e8ecf0";

defaultTheme.tableColors.columnHoverBorder = "#91cce2";
defaultTheme.tableColors.rowHoverBorder = "#91cce2";

defaultTheme.tableColors.activeBorder = "#001f40";
defaultTheme.tableColors.activeBorderHovered = "#001f40";

defaultTheme.tableColors.errorBorder = "#ec4c47";
defaultTheme.tableColors.errorBorderHovered = "#d04e4c";
defaultTheme.tableColors.errorRow = "#fffafa";
defaultTheme.tableColors.errorRowHovered = "#faf6f7";
defaultTheme.tableColors.errorRowAlternate = "#fff6f6";
defaultTheme.tableColors.errorRowAlternateHovered = "#faf2f3";

defaultTheme.tableColors.financialRow = "#a8dcc3";
defaultTheme.tableColors.financialRowAlternate = "#97d3b6";

defaultTheme.tableColors.financial2Row = "#c7f3de";
defaultTheme.tableColors.financial2Alternate = "#aee7cc";

defaultTheme.tableColors.ignoredBorder = "#e2e9f0";
defaultTheme.tableColors.ignoredBorderHovered = "#e2e9f0";

defaultTheme.tableColors.pendingBorder = "#facc36";
defaultTheme.tableColors.pendingBorderHovered = "#facc36";
defaultTheme.tableColors.pendingRow = "#fffcf2";
defaultTheme.tableColors.pendingRowAlternate = "#f8f5ee";

defaultTheme.tableColors.successBorder = "#47b881";
defaultTheme.tableColors.successBorderHovered = "#47b881";
defaultTheme.tableColors.successRow = "#f5fcf9";
defaultTheme.tableColors.successRowHovered = "#f2fcf7";
defaultTheme.tableColors.successRowAlternate = "#ebf5f1";

defaultTheme.tableColors.bulkActionsSectionDivider = "#e2e9f0";
defaultTheme.tableColors.bulkActionsInfo = "#1d7ce2";

defaultTheme.tableColors.infoRow = "#f1f8ff";
defaultTheme.tableColors.infoRowHovered = "#f1f8ff";
defaultTheme.tableColors.infoBorder = "#1d7ce2";

defaultTheme.tableColors.deletedRow = "#f2f2f2";
defaultTheme.tableColors.deletedRowHovered = "#efefef";

defaultTheme.tableColors.specialRow = defaultTheme.colors.lightPurple;
defaultTheme.tableColors.specialRowHovered = defaultTheme.colors.lightPurple;
defaultTheme.tableColors.specialBorder = "#d6dcff";

defaultTheme.tableColors.menuSelectBackgroundColor = "#f6f6f6";
defaultTheme.tableColors.menuSelectTextColor = "#282e34";
defaultTheme.tableColors.menuSelectAltTextColor = "#425a70";

defaultTheme.tableColors.internalCell = "#e2e9f0";

defaultTheme.layoutColors = {};
defaultTheme.layoutColors.border = "#e4eaf0";

defaultTheme.fontWeights.REGULAR = 400;
defaultTheme.fontWeights.MEDIUM = 500;
defaultTheme.fontWeights.DEMI = 600;
defaultTheme.fontWeights.BOLD = 800;

defaultTheme.boxShadow = "0px 0px 3px 0px rgba(149, 161, 174, 0.39)";
defaultTheme.boxShadowHighlighted = "#C7CED4 0px 0px 0px 2px inset";

defaultTheme.rabbetHeaderColor = defaultTheme.defaultColors.headerColor;
defaultTheme.rabbetPrimaryColor = defaultTheme.defaultColors.primaryColor;
defaultTheme.rabbetHeaderAltColor =
  defaultTheme.defaultColors.headerButtonTextColor;
defaultTheme.rabbetHeaderTextOnHoverColor =
  defaultTheme.defaultColors.headerTextOnHoverColor;

defaultTheme.changeOrderChart = {};
defaultTheme.changeOrderChart.EXPOSURE = "#ababab";
defaultTheme.changeOrderChart.POTENTIAL_CHANGE_ORDER = "#ffc800";
defaultTheme.changeOrderChart.EXECUTED_CHANGE_ORDER = "#00a666";

export default defaultTheme;
