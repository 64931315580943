import { useContext } from "react";
import { get } from "lodash";
import { Pane, Text } from "components/materials";
import { minorScale, ThemeContext } from "helpers/utilities";

export function Body({ columns, rows }) {
  return (
    <tbody>
      {rows.map((row, index) => (
        <Row columns={columns} key={`row-${index}`} row={row} />
      ))}
    </tbody>
  );
}

function Row({ columns, row }) {
  const theme = useContext(ThemeContext);
  const borderStyle = `1px solid ${theme.colors.borderGray}`;

  return (
    <tr style={{ height: "48px" }}>
      {columns.map((column, index) => {
        const fallback = (row) => <Text>{row[column.valueKey]}</Text>;
        const renderCell = get(column, "renderCell", fallback);

        return (
          <td
            key={`cell-${index}`}
            style={{
              border: borderStyle,
            }}
          >
            <Pane paddingX={minorScale(3)}>{renderCell(row)}</Pane>
          </td>
        );
      })}
    </tr>
  );
}
