import { useContext } from "react";
import { Button, Pane, Tab, Tablist } from "components/materials";
import { majorScale } from "helpers/utilities";
import { BUDGET_CHART_VIEW } from "helpers/enums";
import translate from "helpers/translate";
import analytics from "helpers/analytics";
import { UserContext } from "helpers/behaviors";
import { BudgetAdjustments } from "./BudgetAdjustments";
import BudgetProgress from "./BudgetProgress";
import { ContingencyUsage } from "./ContingencyUsage";

const ORDERED_BUDGET_CHART_VIEWS = [
  BUDGET_CHART_VIEW.BUDGET_PROGRESS,
  BUDGET_CHART_VIEW.CONTINGENCY_USAGE,
  BUDGET_CHART_VIEW.BUDGET_ADJUSTMENTS,
];

const BUDGET_CHART_VIEWS = {
  [BUDGET_CHART_VIEW.BUDGET_PROGRESS]: BudgetProgress,
  [BUDGET_CHART_VIEW.CONTINGENCY_USAGE]: ContingencyUsage,
  [BUDGET_CHART_VIEW.BUDGET_ADJUSTMENTS]: BudgetAdjustments,
};

export function BudgetVisualizations({
  project,
  setShowVisualizations,
  selectedView,
  setSelectedView,
}) {
  const { organizationId } = useContext(UserContext);

  if (!selectedView) return null;
  const View = BUDGET_CHART_VIEWS[selectedView];

  return (
    <Pane marginTop={1} background="#edeff5" paddingY={majorScale(2)}>
      <Pane
        display="flex"
        alignItems="center"
        marginBottom={majorScale(2)}
        paddingX={majorScale(4)}
      >
        <Tablist flexGrow={1}>
          {ORDERED_BUDGET_CHART_VIEWS.map((view) => (
            <Tab
              key={view}
              isSelected={view === selectedView}
              onSelect={() => {
                if (view === "CONTINGENCY_USAGE")
                  analytics.track("View Project Contingency Graph", {
                    projectId: project.id,
                    organizationId,
                  });
                setSelectedView(view);
              }}
            >
              {translate(`budgetPage.views.${view}`)}
            </Tab>
          ))}
        </Tablist>
        <Button onClick={() => setShowVisualizations(false)}>
          Hide Graphs
        </Button>
      </Pane>
      <Pane height={280}>
        <View project={project} />
      </Pane>
    </Pane>
  );
}
