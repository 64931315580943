import { Fragment, useCallback, useEffect } from "react";
import { Button, Link, Modal } from "components/materials";
import { minorScale } from "helpers/utilities";
import t from "helpers/translate";
import useDropzone from "../../hooks/useDropzone";
import Dropzone from "../Dropzone";
import useCreateDocumentUploadMutation from "../../api/mutations/useCreateUploadMutation";
import {
  useUploadsDispatch,
  ActionType,
} from "../../contexts/FileUploadsContext";

export default function UploadFilesTab({
  selected = false,
  enableTab,
  projectId,
  drawId,
  onCancel,
  onSubmit,
}) {
  useEffect(() => enableTab(), [enableTab]);

  const dispatch = useUploadsDispatch();
  const [createUpload, { loading }] = useCreateDocumentUploadMutation({
    drawId,
    projectId,
  });
  const dropzone = useDropzone({
    disabled: false,
    allowMultiple: true,
  });

  const handleSubmit = useCallback(
    (files) => {
      const uploads = files.map(
        async ({ name, split, numberOfPages, file }) => {
          const result = await createUpload({
            variables: {
              fileName: name,
              pageCount: numberOfPages,
              split,
            },
          });
          const documentUpload = result.data.createDocumentUpload;
          const upload = {
            id: documentUpload.id,
            uploadUrl: documentUpload.uploadUrl,
            file,
          };
          dispatch({
            type: ActionType.QUEUE_FILE,
            payload: upload,
          });
          return upload;
        }
      );

      Promise.all(uploads).then((uploads) => {
        onSubmit && onSubmit(uploads);
      });
    },
    [createUpload, dispatch, onSubmit]
  );

  if (!selected) return null;

  return (
    <Fragment>
      <Modal.Content>
        <Dropzone dropzone={dropzone} />
      </Modal.Content>
      <Modal.Actions display="flex" alignItems="center">
        <Link purpose="splitter help" href={t("splitterHelp.learnMoreLink")}>
          {t("splitterHelp.learnMoreUploadDescription")}
        </Link>
        <Button
          marginLeft="auto"
          marginRight={minorScale(3)}
          onClick={onCancel}
          purpose="upload-files cancel"
          content="Cancel"
        />
        <Button
          appearance="primary"
          disabled={
            dropzone.files.length === 0 ||
            dropzone.files.some(
              ({ numberOfPages, file }) =>
                file.type === "application/pdf" && !numberOfPages
            )
          }
          onClick={() => handleSubmit(dropzone.files)}
          purpose="upload-files submit"
          isLoading={loading}
          content="Upload"
        />
      </Modal.Actions>
    </Fragment>
  );
}
