import { useContext } from "react";
import { Pane, Shortener, Text } from "components/materials";
import { hover } from "glamor";
import t from "helpers/translate";
import { formatTrimDate } from "helpers/dateHelpers";
import { ArrowRightIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";

export function TaskRow({ task }) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const TASK_COLORS = {
    NOT_STARTED: theme.tableColors.errorBorder,
    IN_PROGRESS: theme.tableColors.bulkActionsInfo,
    COMPLETE: theme.colors.textGray,
  };

  return (
    <Pane
      borderTop={`1px solid ${theme.colors.borderGray}`}
      paddingY={minorScale(3)}
      display="flex"
      justifyContent="center"
    >
      <Pane
        display="flex"
        paddingRight={majorScale(3)}
        cursor="pointer"
        onClick={() => history.push(`/projects/${task.projectId}/timeline`)}
        width="30%"
      >
        <Shortener
          color={theme.colors.linkBlue}
          fontSize="14px"
          className={hover({
            textDecoration: "underline",
          })}
          size={400}
          text={task.projectName}
          limit={32}
        />
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane
        display="flex"
        paddingRight={majorScale(3)}
        cursor="pointer"
        onClick={() =>
          history.push(`/projects/${task.projectId}/timeline/${task.id}`)
        }
        width="30%"
      >
        <Shortener
          color={theme.colors.linkBlue}
          fontSize="14px"
          className={hover({
            textDecoration: "underline",
          })}
          size={300}
          text={task.eventName}
          limit={32}
        />
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="20%">
        <Text color={theme.colors.textGray}>
          {formatTrimDate(task.projectedCompletionDate)}
        </Text>
      </Pane>
      <Pane width="20%" display="flex" alignItems="center">
        <Pane
          width="12px"
          height="12px"
          borderRadius="12px"
          backgroundColor={TASK_COLORS[task.status]}
          marginRight={majorScale(1)}
        />
        <Text color={theme.colors.textGray}>
          {t(`taskStatus.${task.status}`)}
        </Text>
      </Pane>
    </Pane>
  );
}
