import { Pane } from "components/materials";
import DataTableFilters from "components/materials/FastDataTable/Controls/Filters";
import CurrentFilters from "components/materials/FastDataTable/Controls/CurrentFilters";
import { majorScale } from "helpers/utilities";

export function Filters({
  filterConfig,
  filterFields,
  projects,
  setFilterConfig,
}) {
  return (
    <Pane marginLeft={majorScale(2)} display="flex" alignItems="center">
      <DataTableFilters
        dataColumns={filterFields}
        filterBy={setFilterConfig}
        filterConfig={filterConfig}
        items={projects}
        marginRight={majorScale(2)}
      />
      <CurrentFilters
        dataColumns={filterFields}
        filterBy={setFilterConfig}
        filterConfig={filterConfig}
      />
    </Pane>
  );
}
