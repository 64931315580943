import { Fragment, useContext, useState } from "react";
import { DocumentShareIcon, NotificationsIcon } from "evergreen-ui";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { Banner, Spinner, Text } from "components/materials";
import { majorScale, ThemeContext } from "helpers/utilities";
import t from "helpers/translate";
import { UploadStatus } from "../types";
import useUploadsQuery from "../api/queries/useUploadsQuery";

export function PendingUploadsBanner({ projectId, drawId = null }) {
  const { data, loading } = useUploadsQuery({
    variables: { projectId, drawId },
    subscribe: true,
  });

  if (loading) return <Spinner />;

  const uploads = get(data, "scope.documentUploads").filter(({ status }) =>
    [UploadStatus.PENDING_CONFIRMATION, UploadStatus.PROCESSING].includes(
      status
    )
  );
  if (!uploads) return null;

  return <UploadsList uploads={uploads} />;
}

function UploadsList({ uploads }) {
  const [showAllUploads, setShowAllUploads] = useState(false);
  const toggleShowAll = () => setShowAllUploads((oldShowAll) => !oldShowAll);

  let visibleUploads = uploads;
  if (!showAllUploads) visibleUploads = uploads.slice(0, 3);

  if (uploads.length === 0) return null;

  return (
    <Fragment>
      <Banner
        borderBottom
        icon={NotificationsIcon}
        mainText={t("uploads.pendingUploads.main")}
      />
      {visibleUploads.map((upload) => (
        <Banner key={upload.id} borderBottom paddingLeft={majorScale(8)}>
          <UploadLink upload={upload} />
        </Banner>
      ))}
      {uploads.length > 3 && (
        <Banner
          borderBottom
          paddingLeft={majorScale(8)}
          mainText={
            showAllUploads
              ? t("uploads.pendingUploads.hidePending", {
                  count: uploads.length - 3,
                })
              : t("uploads.pendingUploads.showPending", {
                  count: uploads.length - 3,
                })
          }
          textProps={{
            color: "selected",
            cursor: "pointer",
            onClick: toggleShowAll,
            size: 300,
          }}
        />
      )}
    </Fragment>
  );
}

function UploadLink({ upload }) {
  const history = useHistory();

  const Upload =
    upload.status === UploadStatus.PROCESSING
      ? ProcessingUpload
      : PendingSplitUpload;

  return (
    <Banner borderBottom paddingLeft={majorScale(8)}>
      <Upload
        upload={upload}
        onClick={() => history.push(`/uploads/${upload.id}`)}
      />
    </Banner>
  );
}

function PendingSplitUpload({ onClick, upload }) {
  const theme = useContext(ThemeContext);

  return (
    <Fragment>
      <DocumentShareIcon marginRight={majorScale(1)} color="selected" />
      <Text
        color="selected"
        cursor="pointer"
        fontWeight={theme.fontWeights.MEDIUM}
        marginRight={majorScale(2)}
        onClick={onClick}
        size={300}
        textDecoration="underline"
      >
        {get(upload, "file.name")}
      </Text>
      <Text color="muted" fontStyle="italic" size={300}>
        {t("uploads.pendingUploads.pages", {
          count: get(upload, "file.pageCount"),
        })}
      </Text>
    </Fragment>
  );
}

function ProcessingUpload({ upload }) {
  const theme = useContext(ThemeContext);
  const pages = get(upload, "file.pageCount");

  return (
    <Fragment>
      <Spinner marginRight={majorScale(1)} size={16} />
      <Text
        color="muted"
        fontStyle="italic"
        marginRight={majorScale(1)}
        size={300}
      >
        {t("uploads.pendingUploads.status.processing")}
      </Text>
      <Text
        color="muted"
        fontWeight={theme.fontWeights.MEDIUM}
        marginRight={majorScale(2)}
        size={300}
      >
        {get(upload, "file.name")}
      </Text>
      {pages && (
        <Text color="muted" fontStyle="italic" size={300}>
          {t("uploads.pendingUploads.pages", {
            count: pages,
          })}
        </Text>
      )}
    </Fragment>
  );
}
