import { Fragment } from "react";
import { Pane } from "components/materials";
import { ScopeOrganizations } from "components/containers";
import { OrganizationSelector } from "components/templates";
import { majorScale } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { InsightCards } from "./InsightCards";

export function PortfolioInsights({ history }) {
  return (
    <ScopeOrganizations scopeToUserPermission={PERMISSION_ACTION.RUN_REPORT}>
      {({
        onOrganizationSelected,
        allOrganizations,
        disabledOrganizations,
        selectedOrganization,
      }) => {
        return (
          <Fragment>
            <Pane margin={majorScale(2)}>
              <OrganizationSelector
                onOrganizationSelected={onOrganizationSelected}
                disabledOrganizations={disabledOrganizations}
                organizations={allOrganizations}
                selectedOrganization={selectedOrganization}
                title="Viewing portfolio for"
              />
            </Pane>
            <Pane
              width="100%"
              backgroundColor="#F4F4F6"
              height="auto"
              display="flex"
              justifyContent="center"
            >
              <Pane
                width="90%"
                maxWidth={1440}
                paddingBottom={majorScale(2)}
                paddingLeft={majorScale(2)}
                paddingRight={majorScale(2)}
              >
                <InsightCards
                  organization={selectedOrganization}
                  allOrganizations={allOrganizations}
                  disabledOrganizations={disabledOrganizations}
                  history={history}
                />
              </Pane>
            </Pane>
          </Fragment>
        );
      }}
    </ScopeOrganizations>
  );
}
