import { useContext, useState } from "react";
import { ArrowRightIcon, ScatterPlotIcon } from "evergreen-ui";
import { max } from "lodash";
import {
  Badge,
  Button,
  Heading,
  IconButton,
  Pane,
  Paragraph,
  Tab,
  Tablist,
  Tooltip,
  ConfigureButtons,
} from "components/materials";
import {
  majorScale,
  minorScale,
  Position,
  ThemeContext,
} from "helpers/utilities";
import { parseDate, addMonths, differenceInMonths } from "helpers/dateHelpers";
import formatPercent from "helpers/formatPercent";
import { requestedOverTimeViewConfig } from "components/containers/BudgetPage/viewConfigs";
import {
  calculateAverageDuration,
  calculateSteepnessForSCurve,
  calculateSCurveDataPoint,
  getCoordinatesFromSlopeGivenY,
  getDataColor,
  getDeviationFromLine,
  getSlopeOfLine,
} from "../graphHelpers";
import { ScheduleRiskGraph } from "./ScheduleRiskGraph";

const GRAPH_TYPE = {
  LINEAR: "linear",
  S_CURVE: "sCurve",
};

function getDeviationWhenSCurve(finalDataPoint, projectMonths) {
  const k = calculateSteepnessForSCurve(projectMonths);
  const x0 = calculateAverageDuration(projectMonths);

  const sCurveCoordinatesForDeviation = calculateSCurveDataPoint(
    k,
    100,
    finalDataPoint.x,
    x0
  );

  return (
    getDeviationFromLine(
      sCurveCoordinatesForDeviation.x,
      sCurveCoordinatesForDeviation.y,
      finalDataPoint.x,
      finalDataPoint.y
    ) / 100
  );
}

function getDeviationWhenLinear(finalDataPoint, projectMonths) {
  // Steps to get the deviation when trend is linear since a slope = 1 is not a guarantee
  // 1. Use the final point of the trend line and the first point to get the slope
  // 2. Use the slope and the desired x-coordinate (getting from the final data point) to get the y-coordinate
  // of the point we want to calculate the deviation (i.e. distance) from
  // 3. Plug the deviation trend line coordinates and the final data point coordinates into the distance formula
  // 4. Divide the deviation by 100 to get it into ratio form
  const trendLineEndCoordinates = {
    x: projectMonths,
    y: 100,
  };
  const slope = getSlopeOfLine(
    0,
    0,
    trendLineEndCoordinates.x,
    trendLineEndCoordinates.y
  );
  const trendLineCoordinatesForDeviation = getCoordinatesFromSlopeGivenY(
    slope,
    finalDataPoint.x
  );

  return (
    getDeviationFromLine(
      trendLineCoordinatesForDeviation.x,
      trendLineCoordinatesForDeviation.y,
      finalDataPoint.x,
      finalDataPoint.y
    ) / 100
  );
}

export const SCHEDULE_RISK_CONFIGURATION_SETTINGS = {
  i: "scheduleRisk",
  x: 1,
  y: 2,
  w: 1,
  h: 1,
  disabled: false,
};

export function ScheduleRisk({
  history,
  isConfigurable,
  isDisabled,
  project,
  cards,
  setCards,
  name,
}) {
  const theme = useContext(ThemeContext);
  const [graphType, setGraphType] = useState(GRAPH_TYPE.LINEAR);

  const noScheduleSetup = !project.expectedProjectLength || !project.startDate;

  const graphData = project.hardCostPercentagesByMonth?.map((datum, index) => ({
    x: index,
    y: datum.hardCostsGrossPercentComplete,
    month: datum.date,
  }));

  const startDate = parseDate(project.startDate);
  const endDate = addMonths(startDate, project.expectedProjectLength);
  const today = parseDate(new Date());
  const projectMonths = max([
    differenceInMonths(endDate, startDate),
    differenceInMonths(today, startDate),
  ]);

  const finalDataPoint =
    graphData.length === 0 ? { x: 0, y: 0 } : graphData[graphData.length - 1];

  const deviation =
    graphType === GRAPH_TYPE.LINEAR
      ? getDeviationWhenLinear(finalDataPoint, projectMonths)
      : getDeviationWhenSCurve(finalDataPoint, projectMonths);

  const { dataColor, badgeColor } = getDataColor(deviation);

  return (
    <Pane
      width="100%"
      height="100%"
      position="relative"
      padding={majorScale(2)}
    >
      <Pane
        id="header"
        marginBottom={noScheduleSetup ? minorScale(3) : minorScale(2)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        paddingBottom={noScheduleSetup ? majorScale(2) : majorScale(1)}
      >
        <Pane display="flex" justifyContent="space-between" alignItems="center">
          <Pane display="flex" alignItems="center">
            <Heading
              fontSize={majorScale(3)}
              lineHeight={1.2}
              fontWeight={500}
              color={isDisabled ? theme.colors.gray600 : null}
            >
              Schedule Risk
            </Heading>
            <ScatterPlotIcon color="muted" marginLeft={minorScale(3)} />
          </Pane>
          <Pane display="flex" alignItems="center">
            {graphData.length > 0 && (
              <Pane textAlign="right" marginRight={minorScale(5)}>
                <Paragraph
                  color={theme.colors.gray700}
                  fontSize={minorScale(3)}
                >
                  Schedule Deviation
                </Paragraph>
                <Tooltip
                  content="The distance from the trendline to the current month of funded draws"
                  position={Position.TOP}
                >
                  <Badge color={badgeColor}>{formatPercent(deviation)}</Badge>
                </Tooltip>
              </Pane>
            )}
            {isConfigurable && (
              <ConfigureButtons
                isDisabled={isDisabled}
                cards={cards}
                setCards={setCards}
                name={name}
              />
            )}
            {!isConfigurable && (
              <IconButton
                icon={ArrowRightIcon}
                onClick={() =>
                  history.push(
                    `/projects/${
                      project.id
                    }/budget?table=${requestedOverTimeViewConfig()}`
                  )
                }
                appearance="minimal"
                border={`1px solid ${theme.colors.gray400}`}
                marginLeft="auto"
              />
            )}
          </Pane>
        </Pane>
        {!noScheduleSetup && (
          <Pane>
            <Tablist>
              <Tab
                aria-controls={`panel-${GRAPH_TYPE.LINEAR}`}
                isSelected={graphType === GRAPH_TYPE.LINEAR}
                key={GRAPH_TYPE.LINEAR}
                onSelect={() => setGraphType(GRAPH_TYPE.LINEAR)}
                height={majorScale(2)}
              >
                Linear
              </Tab>
              <Tab
                aria-controls={`panel=${GRAPH_TYPE.S_CURVE}`}
                isSelected={graphType === GRAPH_TYPE.S_CURVE}
                key={GRAPH_TYPE.S_CURVE}
                onSelect={() => setGraphType(GRAPH_TYPE.S_CURVE)}
                height={majorScale(2)}
              >
                S-curve
              </Tab>
            </Tablist>
          </Pane>
        )}
      </Pane>
      <Pane height="80%">
        {noScheduleSetup ? (
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Paragraph
              width="80%"
              textAlign="center"
              paddingTop={majorScale(3)}
              paddingBottom={majorScale(4)}
            >
              This project&apos;s schedule has not been set up yet.
              <br /> Add a start date and an expected project length.
            </Paragraph>
            <Button
              appearance="primary"
              onClick={() =>
                history.push(
                  `/projects/${project.id}/settings?settings=details`
                )
              }
              purpose="set up project settings"
            >
              Set Up Schedule
            </Button>
          </Pane>
        ) : (
          <ScheduleRiskGraph
            dataColor={dataColor}
            graphData={graphData}
            graphType={graphType}
            projectMonths={projectMonths}
          />
        )}
      </Pane>
    </Pane>
  );
}
