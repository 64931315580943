import { useContext } from "react";
import {
  ConfigureButtons,
  Heading,
  IconButton,
  Pane,
  Text,
  Shortener,
} from "components/materials";
import { max, uniq } from "lodash";
import { add } from "helpers/math";
import { ArrowRightIcon, HistoryIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";
import { hover } from "glamor";
import {
  getSerializedURLForProjectReport,
  trackPortfolioAnalysis,
} from "./helpers";

export const PROJECTS_BY_TEAM_CONFIGURATION_SETTINGS = {
  i: "projectsByTeam",
  x: 1,
  y: 6,
  w: 1,
  h: 1,
  disabled: false,
};

function TeamCount({
  navigateToProjects,
  activeProjectCount,
  maxNumProjects,
  theme,
  name,
}) {
  return (
    <Pane display="flex" marginBottom={majorScale(1)}>
      <Pane
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={navigateToProjects}
        width={300}
        marginRight={majorScale(2)}
      >
        <Shortener
          color={theme.colors.linkBlue}
          className={hover({
            textDecoration: "underline",
          })}
          limit={20}
          size={200}
          text={name}
        />
        <ArrowRightIcon
          color={theme.colors.linkBlue}
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="100%">
        <Pane
          paddingX={minorScale(1)}
          display="flex"
          alignItems="center"
          width={`${max([4, (activeProjectCount / maxNumProjects) * 100])}%`}
          height={30}
          background="#16439B"
        >
          <Pane marginLeft="auto">
            <Text color="white">{activeProjectCount}</Text>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectsByTeam({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  teams,
  projects,
  organization,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const organizationId = organization.id;

  const countForTeam = teams.reduce(
    (acc, team) => ({
      ...acc,
      [team.name]: acc[team.name] ? add(acc[team.name], 1) : 1,
    }),
    {}
  );

  const uniqueTeams = uniq(teams);
  const sortedTeams = [...uniqueTeams].sort(
    (teamA, teamB) => countForTeam[teamB.name] - countForTeam[teamA.name]
  );

  const assignedTotal = Object.keys(countForTeam).reduce(
    (acc, key) => acc + countForTeam[key],
    0
  );

  const noTeamAssignedTotal = projects.length - assignedTotal;

  const maxNumProjects = Math.max(
    countForTeam[sortedTeams[0]?.name],
    noTeamAssignedTotal
  );

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Projects By Team
          </Heading>
          <HistoryIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              history.push("/");
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
          />
        )}
      </Pane>
      <Pane height="85%" paddingTop={majorScale(2)}>
        <Pane height="100%" overflowY="scroll">
          {sortedTeams.map((team) => {
            const { name: teamName } = team;
            const navigateToProjects = () => {
              trackPortfolioAnalysis(
                organizationId,
                "Go to Projects (filtered by team)"
              );
              return history.push(
                getSerializedURLForProjectReport(
                  "team",
                  {
                    enum: [teamName],
                  },
                  organizationId
                )
              );
            };

            return (
              <TeamCount
                key={team.id}
                name={team.name}
                theme={theme}
                maxNumProjects={maxNumProjects}
                navigateToProjects={navigateToProjects}
                activeProjectCount={countForTeam[team.name]}
              />
            );
          })}
          {noTeamAssignedTotal !== 0 && (
            <TeamCount
              navigateToProjects={() => {
                trackPortfolioAnalysis(
                  organizationId,
                  "Go to Projects (filtered by team)"
                );
                return history.push(
                  getSerializedURLForProjectReport("team", {
                    enum: ["-"],
                  })
                );
              }}
              maxNumProjects={maxNumProjects}
              name="Unassigned"
              theme={theme}
              activeProjectCount={noTeamAssignedTotal}
            />
          )}
        </Pane>
      </Pane>
    </Pane>
  );
}
