import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { useBeforeUnload } from "helpers/behaviors/NavigationWarnings";
import { Upload, UploadStatus } from "../types";
import useFileUploadedMutation from "../api/mutations/useFileUploadedMutation";

export type State = {
  [key: string]: Upload;
};

export enum ActionType {
  QUEUE_FILE,
  FILE_UPLOADING,
  FILE_UPLOADED,
  UPLOAD_FAILED,
}

export type Action =
  | {
      type: ActionType.QUEUE_FILE;
      payload: {
        id: string;
        file: File;
      };
    }
  | {
      type: ActionType.FILE_UPLOADING;
      payload: {
        id: string;
        progress: number;
        file: File;
      };
    }
  | {
      type: ActionType.FILE_UPLOADED;
      payload: {
        id: string;
        file: File;
      };
    }
  | {
      type: ActionType.UPLOAD_FAILED;
      payload: {
        id: string;
        reason: any;
      };
    };

export const UploadsContext = createContext<State>({});

export const UploadsDispatchContext = createContext<Function>(() => {
  throw new Error("UploadsDispatchContext not provided");
});

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.QUEUE_FILE:
      return {
        ...state,
        [action.payload.id]: { ...action.payload, status: UploadStatus.QUEUED },
      };
    case ActionType.FILE_UPLOADING: {
      const upload = state[action.payload.id];
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          status:
            upload.status === UploadStatus.QUEUED
              ? UploadStatus.UPLOADING
              : upload.status,
          progress:
            action.payload?.progress ?? state[action.payload.id]?.progress ?? 0,
        },
      };
    }
    case ActionType.FILE_UPLOADED: {
      const { [action.payload.id]: _, ...rest } = state;
      return rest;
    }
    case ActionType.UPLOAD_FAILED:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          status: UploadStatus.ERROR,
        },
      };
    default:
      return state;
  }
}

function useOnQueued(dispatch: Function) {
  const [fileUploaded] = useFileUploadedMutation();

  return useCallback(
    (upload: Upload) => {
      if (upload.status !== UploadStatus.QUEUED) return;
      if (!upload.uploadUrl || !upload.file) return;

      const { uploadUrl, file } = upload;

      dispatch({
        type: ActionType.FILE_UPLOADING,
        payload: { id: upload.id },
      });

      upload.file
        .arrayBuffer()
        .then((data) =>
          axios.put(uploadUrl, data, {
            headers: { "Content-Type": file.type },
            onUploadProgress: (progressEvent) => {
              const { total = 1, loaded = 0 } = progressEvent;
              const progress = loaded / total;

              dispatch({
                type: ActionType.FILE_UPLOADING,
                payload: {
                  id: upload.id,
                  progress,
                },
              });
            },
          })
        )
        .then(() => fileUploaded({ variables: { id: upload.id } }))
        .then(() =>
          dispatch({
            type: ActionType.FILE_UPLOADED,
            payload: { id: upload.id },
          })
        )
        .catch((reason) =>
          dispatch({
            type: ActionType.UPLOAD_FAILED,
            payload: { id: upload.id, reason },
          })
        );
    },
    [dispatch, fileUploaded]
  );
}

const initialState: State = {};

export function FileUploadProvider({ children }: { children: any }) {
  const [uploads, dispatch] = useReducer(reducer, initialState);
  const onQueued = useOnQueued(dispatch);
  const hasPendingFiles = Object.values(uploads).some(
    (upload) =>
      upload.status &&
      [UploadStatus.UPLOADING, UploadStatus.QUEUED].includes(upload.status)
  );

  useBeforeUnload(hasPendingFiles);

  useEffect(() => {
    Object.values(uploads).forEach((upload) => {
      if (upload.status === UploadStatus.QUEUED) {
        onQueued(upload);
      }
    });
  }, [uploads, onQueued]);

  return (
    <UploadsContext.Provider value={uploads}>
      <UploadsDispatchContext.Provider value={dispatch}>
        {children}
      </UploadsDispatchContext.Provider>
    </UploadsContext.Provider>
  );
}

export function useUploads() {
  return useContext(UploadsContext);
}

export function useUploadsDispatch() {
  return useContext(UploadsDispatchContext);
}
