import { useContext } from "react";
import {
  IconButton,
  Pane,
  Heading,
  ConfigureButtons,
} from "components/materials";
import { ArrowRightIcon, HistoryIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";

export const PROJECT_COSTS_CONFIGURATION_SETTINGS = {
  i: "projectCosts",
  x: 0,
  y: 5,
  w: 1,
  h: 1,
  disabled: false,
};

export function ProjectCosts({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Project Costs
          </Heading>
          <HistoryIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              history.push("/");
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
          />
        )}
      </Pane>
    </Pane>
  );
}
