import { useEffect, useRef, useState } from "react";
import { orderBy } from "lodash";
import { Pane } from "components/materials";
import { Body, Footer, Header } from ".";

export function Table({
  columns,
  defaultSortDirection,
  defaultSortKey,
  footerProps = {},
  hasFooter = true,
  rows,
  ...containerStyleProps
}) {
  const [sortKey, setSortKey] = useState(defaultSortKey || columns[0]?.sortKey);
  const [sortDirection, setSortDirection] = useState(
    defaultSortDirection || "asc"
  );

  const container = useRef(null);

  useEffect(() => {
    const containerDiv = container.current;

    if (containerDiv) {
      containerDiv.scrollTop = 0;
    }
  }, [sortDirection, sortKey]);

  const sortedRows = orderBy(rows, sortKey, sortDirection);

  return (
    <Pane
      flex="1"
      maxHeight="300px"
      overflow="auto"
      ref={container}
      {...containerStyleProps}
    >
      <table
        style={{
          borderCollapse: "collapse",
          borderStyle: "hidden",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <Header
          columns={columns}
          currentSortDirection={sortDirection}
          currentSortKey={sortKey}
          setSortDirection={setSortDirection}
          setSortKey={setSortKey}
        />
        <Body columns={columns} rows={sortedRows} />
        {hasFooter && (
          <Footer columns={columns} footerProps={footerProps} rows={rows} />
        )}
      </table>
    </Pane>
  );
}
