import { useContext, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  ConfigureButtons,
  Heading,
  IconButton,
  Pane,
  Text,
  Shortener,
  EmptyCard,
} from "components/materials";
import { max, uniq, flatten } from "lodash";
import { add } from "helpers/math";
import { ArrowRightIcon, HistoryIcon } from "evergreen-ui";
import { majorScale, minorScale, ThemeContext } from "helpers/utilities";
import { useHistory } from "react-router-dom";
import { hover } from "glamor";
import {
  getSerializedURLForProjectReport,
  trackPortfolioAnalysis,
} from "./helpers";
import { PORTFOLIO_VENDORS_QUERY } from "./graphql";

export const PROJECTS_BY_VENDOR_CONFIGURATION_SETTINGS = {
  i: "projectsByVendor",
  x: 0,
  y: 6,
  w: 1,
  h: 1,
  disabled: false,
};

function VendorCount({
  navigateToProjects,
  activeProjectCount,
  maxNumProjects,
  name,
}) {
  return (
    <Pane display="flex" marginBottom={majorScale(1)}>
      <Pane
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={navigateToProjects}
        width={300}
        marginRight={majorScale(2)}
      >
        <Shortener
          color="#2952CC"
          className={hover({
            textDecoration: "underline",
          })}
          limit={20}
          size={200}
          text={name}
        />
        <ArrowRightIcon
          color="#2952CC"
          icon={ArrowRightIcon}
          appearance="minimal"
          marginLeft="auto"
        />
      </Pane>
      <Pane width="100%">
        <Pane
          paddingRight={minorScale(1)}
          display="flex"
          alignItems="center"
          width={`${max([6, (activeProjectCount / maxNumProjects) * 100])}%`}
          height={30}
          background="#16439B"
        >
          <Pane marginLeft="auto">
            <Text color="white">{activeProjectCount}</Text>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
}

export function ProjectsByVendor({
  isConfigurable,
  isDisabled,
  cards,
  setCards,
  name,
  organization,
}) {
  const history = useHistory();
  const theme = useContext(ThemeContext);

  const useVendorsQuery = () =>
    useQuery(PORTFOLIO_VENDORS_QUERY, {
      variables: { organizationId: organization.id },
    });

  const useVendors = useCallback(useVendorsQuery, [organization.id]);

  const { data, loading } = useVendors();

  if (loading) {
    return (
      <EmptyCard
        name="Projects By Vendor"
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        cardName={name}
        cards={cards}
        setCards={setCards}
        icon={<HistoryIcon color="muted" />}
      >
        <Text>Loading...</Text>
      </EmptyCard>
    );
  }

  const organizationId = organization.id;

  const vendors = flatten(
    data.organization.projects.map((project) => project.vendors)
  );

  const countForVendor = vendors.reduce(
    (acc, vendor) => ({
      ...acc,
      [vendor.id]: acc[vendor.id] ? add(acc[vendor.id], 1) : 1,
    }),
    {}
  );

  const uniqueVendors = uniq(vendors);

  const sortedVendors = [...uniqueVendors].sort(
    (vendorA, vendorB) =>
      countForVendor[vendorB.id] - countForVendor[vendorA.id]
  );

  const maxNumProjects =
    sortedVendors.length === 0 ? 0 : countForVendor[sortedVendors[0].id];

  return (
    <Pane height="100%" width="100%" padding={majorScale(2)}>
      <Pane
        display="flex"
        marginBottom={minorScale(3)}
        borderBottom={`1px solid ${theme.colors.gray100}`}
        justifyContent="space-between"
        paddingBottom={majorScale(2)}
      >
        <Pane display="flex" alignItems="center">
          <Heading
            fontSize={majorScale(3)}
            fontWeight={500}
            lineHeight={1.2}
            color={isDisabled ? theme.colors.gray600 : null}
          >
            Projects By Vendor
          </Heading>
          <HistoryIcon color="muted" marginLeft={minorScale(3)} />
        </Pane>
        {isConfigurable && (
          <ConfigureButtons
            isDisabled={isDisabled}
            cards={cards}
            setCards={setCards}
            name={name}
          />
        )}
        {!isConfigurable && (
          <IconButton
            icon={ArrowRightIcon}
            onClick={() => {
              history.push("/");
            }}
            appearance="minimal"
            border={`1px solid ${theme.colors.gray400}`}
          />
        )}
      </Pane>
      <Pane height="85%" paddingTop={majorScale(2)}>
        <Pane height="100%" overflowY="scroll">
          {sortedVendors.map((vendor) => {
            const { name: vendorName } = vendor;
            const navigateToProjects = () => {
              trackPortfolioAnalysis(
                organizationId,
                "Go to Projects (filtered by vendor)"
              );
              return history.push(
                getSerializedURLForProjectReport(
                  "vendor",
                  {
                    enum: [vendorName],
                  },
                  organizationId
                )
              );
            };

            return (
              <VendorCount
                key={vendor.id}
                name={vendor.name}
                maxNumProjects={maxNumProjects}
                navigateToProjects={navigateToProjects}
                activeProjectCount={countForVendor[vendor.id]}
              />
            );
          })}
        </Pane>
      </Pane>
    </Pane>
  );
}
